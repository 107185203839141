import NextLink, { LinkProps } from "next/link";
import { Box, Flex, FlexProps } from "@chakra-ui/react";
import Logo from "public/assets/pitchable-logo.min.svg?react";

type Props = FlexProps & Partial<Pick<LinkProps, "href">>;

export default function NavBarLogo({ children, href = "/", ...rest }: Props) {
  return (
    <NextLink href={href} passHref legacyBehavior>
      <Flex
        id="NavBar-home-logo"
        as="a"
        padding="0.9375rem 1rem"
        marginRight="1rem"
        marginTop=".1em"
        alignItems="center"
        {...rest}
      >
        <Box as={Logo} w="11.75rem" />
        {children}
      </Flex>
    </NextLink>
  );
}
