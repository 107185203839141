import { ForwardedRef, forwardRef } from "react";
import {
  Box,
  HTMLChakraProps,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { OptionalKeys } from "utils/typescriptUtilis";

export type LinkProps = HTMLChakraProps<"span"> &
  Pick<ChakraLinkProps, "isExternal" | "target"> &
  OptionalKeys<NextLinkProps, "href">;

function Link(
  {
    href,
    as,
    replace,
    scroll,
    shallow,
    passHref = true,
    prefetch,
    locale,
    target,
    ...rest
  }: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  if (!href) {
    return <Box ref={ref} as="span" {...rest} />;
  }

  if (rest.isExternal) {
    return <ChakraLink ref={ref} {...rest} />;
  }

  return (
    <NextLink
      {...{ href, as, replace, scroll, shallow, passHref, prefetch, locale }}
      legacyBehavior
    >
      <ChakraLink {...{ ref, target }} {...rest} />
    </NextLink>
  );
}

export default forwardRef(Link);
