import { ReactNode } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import ErrorBoundary from "components/Layout/ErrorBoundary";
import { CONTENT_FULL_HEIGHT, NAVBAR_HEIGHT } from "./constants";

type Props = Partial<BoxProps> & {
  children: ReactNode;
  isFullHeight?: boolean;
  isWithNavBar?: boolean;
};

export default function BaseContentContainer({
  children,
  isFullHeight,
  isWithNavBar,
  ...rest
}: Props) {
  return (
    <>
      <Box
        minHeight={isWithNavBar ? CONTENT_FULL_HEIGHT : "100vh"}
        height={isFullHeight ? "100%" : undefined}
        {...rest}
      >
        <ErrorBoundary isWithNavBar={isWithNavBar}>{children}</ErrorBoundary>
      </Box>

      <style jsx global>{`
        ${isFullHeight ? "html { height: 100%; }" : ""}
        body {
          ${isWithNavBar ? `padding-top: ${NAVBAR_HEIGHT} !important;` : ""}
          ${isFullHeight ? `height: 100%;` : ""}
        }
        ${isFullHeight ? "#__next { height: 100%; }" : ""}
      `}</style>
    </>
  );
}
